<template>
    <div>
        <div class="DESKTOP">
            <div style="background-color:rgba(255,255,255,0.9);position:fixed;width:100vw;z-index:1;height:70px;">
                <ul style="list-style:none;float:left;margin-left:300px;padding:10px;">
                    <li style="float:left;"><router-link to="/">Start</router-link></li>
                    <li style="float:left;"><router-link to="/aktuelles">Aktuelles</router-link></li>
                    <li style="float:left;"><router-link to="/wochenmarkt">Wochenmarkt</router-link></li>
                    <li style="float:left;"><router-link to="/events">Events</router-link></li>
                    <li style="float:left;"><router-link to="/mitglieder">Mitglieder</router-link></li>
                    <li style="float:left;"><router-link to="/umland">Das Umland</router-link></li>
                    <li style="float:left;"><router-link to="/vorstand">Vorstand</router-link></li>
                    <li style="float:left;"><router-link to="/kontakt">Kontakt</router-link></li>
                    <!--<li style="float:left;"><router-link to="/weihnachtsmarkt">Weihnachtsmarkt🎄</router-link></li>-->
                </ul>
            </div>
            <div>
                <router-link to="/"><img alt="wgh_logo" style="position:absolute;top:15px;left:40px;width:150px;z-index:2;" src="/bilder/hennen_logo.png" /></router-link>
                <router-link to="/" class="TABLET" style="position:fixed;right:200px;top:25px;z-index:2;">Werbegemeinschaft Hennen</router-link>
            </div>
        </div>
        <div class="MOBILE">
            <div style="background-color:rgba(255,255,255,0.9);position:fixed;top:0;width:100vw;z-index:3;height:60px;">
            </div>
            <div>
                <Collapse :value="show_menue" style="position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:2;padding-top:50px;overflow-y:auto;" :style="{'background':'white url('+require('../assets/bilder/familie.png')+') no-repeat','background-size':'100% auto'}">
                    <ul style="list-style:none;margin-left:1vw;padding:10px;">
                    <li><router-link @click="Switch" to="/">Start</router-link></li>
                    <li><router-link @click="Switch" to="/aktuelles">Aktuelles</router-link></li>
                    <li><router-link @click="Switch" to="/wochenmarkt">Wochenmarkt</router-link></li>
                    <li><router-link @click="Switch" to="/events">Events</router-link></li>
                    <li><router-link @click="Switch" to="/mitglieder">Mitglieder</router-link></li>
                    <li><router-link @click="Switch" to="/umland">Das Umland</router-link></li>
                    <li><router-link @click="Switch" to="/vorstand">Vorstand</router-link></li>
                    <li><router-link @click="Switch" to="/kontakt">Kontakt</router-link></li>
                    <!--<li><router-link @click="Switch" to="/weihnachtsmarkt">Weihnachtsmarkt</router-link></li>-->
                    <li>&nbsp;</li>
                </ul>
                </Collapse>
            </div>
            <div>
                <router-link to="/"><img alt="wgh_logo" style="position:absolute;top:2px;left:15px;width:56px;z-index:3;" src="/bilder/hennen_logo.png" /></router-link>
                <button @click="Switch()" style="position:fixed;right:20px;top:0px;z-index:3;width:60px;height:60px;">
                    <div v-if="!show_menue">
                        <svg height="50" width="50">
                            <line x1="10" y1="15" x2="40" y2="15" style="stroke:#888;stroke-width:3" />
                            <line x1="10" y1="25" x2="40" y2="25" style="stroke:#888;stroke-width:3" />
                            <line x1="10" y1="35" x2="40" y2="35" style="stroke:#888;stroke-width:3" />
                        </svg>
                    </div>
                    <div v-else>
                        <svg height="50" width="50">
                            <line x1="15" y1="15" x2="35" y2="35" style="stroke:#888;stroke-width:3" />
                            <line x1="15" y1="35" x2="35" y2="15" style="stroke:#888;stroke-width:3" />
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Collapse from './Collapse.vue';

export default {
    components:{
        Collapse
    },
    data(){
        return {
            show_menue:Boolean,
            updatekey:0
        }
    },
    created(){
        this.show_menue=false
    },
    methods:{
        Switch(){
            this.show_menue=!this.show_menue;
        }
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
    color:#054C7D;
}
a:hover{
    text-decoration: underline;
}
li{
    padding:0 10px 0 10px;
}
.MOBILE li{
    margin:25px 0 25px 0;
}
.DESKTOP li{
    padding:0 20px 0 20px;
}
button{
    border:none;
    background-color:rgba(0,0,0,0);
}
</style>