import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: '/', name: 'Start', component: () => { return import('../views/Start.vue') },meta: {title: 'Werbegemeinschaft Hennen'} },
  { path: '/aktuelles', name: 'Aktuelles', component: () => { return import('../views/Aktuelles.vue') } },
  { path: '/wochenmarkt', name: 'Wochenmarkt', component: () => { return import('../views/Wochenmarkt.vue') } },
  { path: '/events', name: 'Events', component: () => { return import('../views/Events.vue') } },
  { path: '/umland', name: 'Umland', component: () => { return import('../views/Umland.vue') } },
  { path: '/kontakt', name: 'Kontakt', component: () => { return import('../views/Kontakt.vue') } },
  { path: '/vorstand', name: 'Vorstand', component: () => { return import('../views/Vorstand.vue') } },
  { path: '/mitglieder', name: 'Mitglieder', component: () => { return import('../views/Mitglieder.vue') } },
  { path: '/impressum', name: 'Impressum', component: () => { return import('../views/Impressum.vue') } },
  { path: '/datenschutz', name: 'Datenschutz', component: () => { return import('../views/Datenschutz.vue') } },
  { path: '/weihnachtsmarkt', name: 'Weihnachtsmarkt', component: () => { return import('../views/Archiv/Weihnachtsmarkt23.vue') } },
  { path: '/archiv', name: 'Archiv', component: () => { return import('../views/Archiv.vue') } },
  { path: '/newsarchiv', name: 'NewsArchiv', component: () => { return import('../views/NewsArchiv.vue') } },
  { path: '/archiv/sommerfest-17', name: 'Sommerfest17', component: () => { return import('../views/Archiv/Sommerfest17.vue') } },
  { path: '/archiv/sommerfest-19', name: 'Sommerfest19', component: () => { return import('../views/Archiv/Sommerfest19.vue') } },
  { path: '/archiv/weihnachtsmarkt-17', name: 'Weihnachtsmarkt17', component: () => { return import('../views/Archiv/Weihnachtsmarkt17.vue') } },
  { path: '/archiv/weihnachtsmarkt-18', name: 'Weihnachtsmarkt18', component: () => { return import('../views/Archiv/Weihnachtsmarkt18.vue') } },
  { path: '/archiv/weihnachtsmarkt-19', name: 'Weihnachtsmarkt19', component: () => { return import('../views/Archiv/Weihnachtsmarkt19.vue') } },
  { path: '/archiv/weihnachtsmarkt-21', name: 'Weihnachtsmarkt21', component: () => { return import('../views/Archiv/Weihnachtsmarkt21.vue') } },
  { path: '/archiv/weihnachtsmarkt-22', name: 'Weihnachtsmarkt22', component: () => { return import('../views/Archiv/Weihnachtsmarkt22.vue') } },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

/*const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

export default router*/
