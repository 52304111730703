/*import Vue from 'vue';
import VueTouch from 'vue-touch';
import App from './App.vue';
import ConstData from './js/ConstData.js';
import router from './router';

Vue.config.productionTip = false

Vue.prototype.$constdata=new ConstData()

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')

Vue.use(VueTouch);*/

import { createApp } from 'vue';
import App from './App.vue';
import ConstData from './js/ConstData.js';
import router from './router';

const app = createApp(App)
app.provide('$constdata', new ConstData())
app.use(router).mount('#app')