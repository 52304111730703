<template>
    <div>
        <div  v-if="show" style="position:fixed;bottom:0%;width:100%;background-color:#054C7D;border:5px solid white;box-sizing: border-box;">
            <div class="text" style="color:white;text-align:center">
                <div style="padding:10px;font-size:1.2em;text-decoration:underline;">
                    <strong>
                    Privatsphäre-Einstellungen
                    </strong>
                </div>
                <div>Diese Webseite verwendet selber keine Cookies.<br>Wir möchten allerdings darauf hinweisen, dass über Links und Videos externe Verbindungen aufgebaut werden, die Cookies nutzen und spezielle Daten erheben können.</div>
                <div style="padding:20px;"><div class="btn" v-on:click="Accept">Kenntnisnahme bestätigen</div></div>
                <div>
                    <router-link style="color:lightgray;margin:0 10px 0 10px" to="/datenschutz">Datenschutzerklärung</router-link>
                    &#8226;
                    <router-link style="color:lightgray;margin:0 10px 0 10px" to="/impressum">Impressum</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            show:false
        }
    },
    methods:{
        Accept(){
            localStorage.setItem('privacy_accept',true)
            this.show=false;
        }
    },
    created(){
        var pa = localStorage.getItem('privacy_accept')
        if(pa == undefined || pa == null || !pa){
            this.show=true
        }
    }
}
</script>

<style scoped>
.btn{
    padding:10px;
    background-color:rgb(103, 138, 163);
    border-radius: 3px;
    display:initial;
}

.btn:hover{
    cursor: pointer;
    background-color:rgb(32, 82, 117);
    border:1px solid white;
}
</style>
