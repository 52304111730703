<template>
    <div style="width:100vw;">
        <div class="backg" style="position:relative;min-height:50px;">
            <div class="mg paddingfooter" style="color:white">
                <div class="mg_4" style="margin-bottom:30px;">
                    <h5 style="font-size:1.2em;margin:0;"><strong>Werbegemeinschaft Hennen</strong></h5>
                    <p>
                        58640 Iserlohn
                        <br>
                        ☎ +(49) 0 23 04 - 95 36 66
                        <br>
                        <a style="color:white;" href="mailto:info@werbegemeinschaft-hennen.eu">info@werbegemeinschaft-hennen.eu</a>
                        <br><br>
                        <router-link style="color:white" to="/impressum">Impressum</router-link>
                        <br>
                        <router-link style="color:white" to="/datenschutz">Datenschutzerklärung</router-link>
                        <br>
                        <router-link style="color:white" to="/kontakt">Kontakt</router-link>
                    </p>
                </div>
                <div class="mg_4" style="margin-bottom:30px;">
                    <h5 style="font-size:1.2em;margin:0;"><strong>Schnellreise</strong></h5>
                    <ul>
                        <li><router-link style="color:white" to="/mitglieder">Mitglieder auf einem Blick</router-link></li>
                        <li><router-link style="color:white" to="/aktuelles">Neuigkeiten aus dem Verein</router-link></li>
                        <li><router-link style="color:white" to="/wochenmarkt">Wochenmarkt als Konzept</router-link></li>
                        <li><router-link style="color:white" to="/umland">Das Hennener Fernglas</router-link></li>
                        <li><router-link style="color:white" to="/archiv">Ein Rückblick auf Hennen</router-link></li>
                    </ul>
                </div>
                <div class="mg_4" style="margin-bottom:30px;">
                    <h5 style="font-size:1.2em;margin:0;"><strong>Download</strong></h5>
                    <ul style="list-style:none;">
                        <li><a style="color:white;" href="/resources/neu-mitgliedsantrag-wgh.pdf"><img style="margin-top:-3px;margin-right:3%;" src="/bilder/pdf_klein.png" alt="pdf">Mitgliederantrag</a></li>
                        <li><a style="color:white;" href="/resources/satzung-werbegemeinschaft-hennen.pdf"><img style="margin-top:-3px;margin-right:3%;" src="/bilder/pdf_klein.png" alt="pdf">Satzung</a></li>
                    </ul>
                </div>
                <div class="mg_4" style="margin-bottom:30px;">
                    <a href="https://www.facebook.com/Werbegemeinschaft-Hennen-2001099406769698/" target="_blank" style="color:white;" rel="noopener noreferrer">
                        <div style="display:flex">
                            <svg width="50px" version="1.1" viewBox="0 0 131.23 130.44" xmlns="http://www.w3.org/2000/svg" style="background-color:white;border-radius:50px;padding:2px;">
                                <g>
                                    <circle cx="65.615" cy="65.615" r="63" fill="white" />
                                </g>
                                <g transform="translate(-53.824 -60.268)">
                                    <path d="m185.06 125.88c0-36.248-29.369-65.617-65.617-65.617-36.248 0-65.617 29.369-65.617 65.617 0 32.75 23.995 59.896 55.364 64.823v-45.855h-16.669v-18.968h16.669v-14.457c0-16.444 9.7896-25.527 24.784-25.527 7.1808 0 14.69 1.2806 14.69 1.2806v16.14h-8.2762c-8.1492 0-10.692 5.0588-10.692 10.247v12.316h18.198l-2.9104 18.968h-15.288v45.855c31.369-4.9265 55.364-32.073 55.364-64.823z" fill="#054c7d" stroke-width=".26458"/>
                                </g>
                            </svg>
                            <span style="margin: auto 0 auto 10px;">Besuchen Sie uns auf Facebook</span>
                        </div>
                    </a>
                    <br>
                </div>
            </div>
        </div>
        <div style="position:relative;background-color:#494949;text-align: center;color: #a8a6a6;padding-top: 0.6em;padding-bottom: 0.6em;">
            <strong>© Werbegemeinschaft Hennen</strong>
        </div>    
    </div>
</template>

<style scoped>
a{
    text-decoration: none;
}
a:hover{
    text-decoration: underline;
}

.paddingfooter{
    padding:5% 5% 5% 5%;
}
@media (min-width: 1400px) {
    .paddingfooter{
        padding:60px 10vw 30px 10vw;
    }
}
</style>