<template>
    <transition name="fade">
        <div v-if="value">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
  props: {
      value:Boolean
  }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
    transition: all 0.5s linear;
    max-height:100vh;
    opacity: 1;
}

.fade-enter, .fade-leave-to {
    max-height:10px;
    opacity: 0;
}
</style>