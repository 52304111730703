<template>
  <div>
      <div class="scrollifmobile">
        <Header/>
        <div>
            <router-view/>
            <a  style="position: fixed;border-radius: 30px;top: 400px;right: 1%;width:50px;"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Werbegemeinschaft-Hennen-2001099406769698/" target="_blank" class="DESKTOP">
              <svg version="1.1" viewBox="0 0 131.23 130.44" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(-53.824 -60.268)">
                <path d="m185.06 125.88c0-36.248-29.369-65.617-65.617-65.617-36.248 0-65.617 29.369-65.617 65.617 0 32.75 23.995 59.896 55.364 64.823v-45.855h-16.669v-18.968h16.669v-14.457c0-16.444 9.7896-25.527 24.784-25.527 7.1808 0 14.69 1.2806 14.69 1.2806v16.14h-8.2762c-8.1492 0-10.692 5.0588-10.692 10.247v12.316h18.198l-2.9104 18.968h-15.288v45.855c31.369-4.9265 55.364-32.073 55.364-64.823z" fill="#054c7d" stroke-width=".26458"/>
              </g>
              </svg>
            </a>
        </div>
        <Footer />
        <Cookie />
    </div>
  </div>
</template>

<script>
import Cookie from "./components/Cookie.vue";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";

export default {
  components:{
    Header,
    Footer,
    Cookie
  }
}
</script>

<style>

body{
  font-family: "Roboto", sans-serif;
  margin:0;
}

.text{
	font-size:1.2em;
	color:#444;
  line-height: 1.42857143;
}

a{
  color:#0967A8;
  text-decoration:none;
}

a:hover{
  text-decoration: underline;
}

.fontcolor{
  color:#054C7D;
}

.backg{
  background-color:#054C7D;
}

b,strong{
  font-weight: 700;
}

.paddingtext{
  padding:3% 5% 3% 5%;
}

.darker_row{
	background-color:rgba(240,240,240,1);
}

.brighter_row{
  background-color: white;
}

.color_row_parent .color_row:nth-child(even){
  background-color:rgba(240,240,240,1);
}

.color_row_parent .color_row:nth-child(odd){
  background-color:white;
}

.color_row_parent_reverse .color_row:nth-child(even){
  background-color:white;
}

.color_row_parent_reverse .color_row:nth-child(odd){
  background-color:rgba(240,240,240,1);
}

.mediaimg{
	width:100%;
}

.shadow{
	box-shadow: 3px 3px 4px grey;
}

.umland_bluebg{
    background-color:#054C7D;
    color:white;
    text-align:center;
    padding:5px;
}

.scrollifmobile{
  overflow-x:hidden;
  overflow-y:auto;
  height:100vh;
}

.fa {
  padding: 10px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.MOBILE{
    display:block;
}
.DESKTOP{
    display:none;
}
@media (min-width: 1400px) {
    .MOBILE{
        display:none;
    }
    .DESKTOP{
        display:block;
    }
    .paddingtext{
      padding:3% 15% 3% 15%;
    }
    .scrollifmobile{
      overflow-x:hidden;
      overflow-y:auto;
      height:auto;
    }
}
@media (max-width:1600px){
  .TABLET{
    display:none;
  }
}

.first_row{
  padding-top:150px;
}

/*.mg{
    display: grid;
}
@media (min-width: 600px) {
  .mg_2 { grid-template-columns: repeat(1, 1fr); }
  .mg_3 { grid-template-columns: repeat(1, 1fr); }
  .mg_4 { grid-template-columns: repeat(1, 1fr); }
  .mg_12 { grid-template-columns: repeat(1, 1fr); }
}
@media (min-width: 900px) {
  .mg_2 { grid-template-columns: repeat(2, 1fr); }
  .mg_3 { grid-template-columns: repeat(3, 1fr); }
  .mg_4 { grid-template-columns: repeat(4, 1fr); }
  .mg_12 { grid-template-columns: repeat(12, 1fr); }
}*/

.mg{
  display:flex;
  flex-direction: row;
}

.mg_1{ width:8.3333334%; }
.mg_2{ width:16.666667%; }
.mg_3{ width:25%; }
.mg_4{ width:33.3333334%; }
.mg_5{ width:41.66667%; }
.mg_6{ width:50%; }
.mg_7{ width:58.333334%; }
.mg_8{ width:66.6666667%; }
.mg_9{ width:75%; }
.mg_10{ width:83.333334%; }
.mg_11{ width:91.666667%; }
.mg_12{ width:100%; }

@media (max-width: 800px) {
  .mg{ flex-direction: column; }
  .mg_2,.mg_3,.mg_4,.mg_5,.mg_6,.mg_7,.mg_8,.mg_9,.mg_10,.mg_11,.mg_12 { width:100%; }
}
</style>
